@import "~/node_modules/bootswatch/dist/flatly/bootstrap.min.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
p {
  margin: 0;
}
/*******************************************************************************/
/*******navbar******************************************************************/

.navbar {
  font-size: 1.6rem;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.3s ease-in;
}
.navbar-nav {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
}
.container-fluid {
  padding: 0 20rem;
}
.navbar-brand {
  font-size: 1.6rem;
  font-weight: bolder;
}
.btn-group {
  margin-right: 6rem;
}
.dropdown-menu {
  font-size: 1.6rem;
  width: 30rem;
  height: 30rem;
  z-index: 2;
  overflow: auto;
}
.icon-card {
  font-size: 2rem;
}
.navbar-bg {
  background-color: rgba(77, 101, 124, 0.9);
}
.cart-container-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cardcart-container-dropdown {
  border-bottom: 1px solid #333;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.icon-trash-dropdown {
  font-size: 1.2rem;
}
.img-cart-dropdown {
  height: 5rem;
  width: 5rem;
}
.text-cart-dropdown {
  font-size: 1.6rem;
  align-self: center;
}
.title-cart-dropdown {
  font-size: 1.2rem;
}
.cart-price-dropdown {
  font-size: 1rem;
  font-weight: bold;
}
.cart-quantity-dropdown {
  font-size: 1rem;
  font-weight: bold;
}
.total-cart-dropdown {
  font-size: 1.2rem;
}
.total-value-dropdown {
  font-size: 1.2rem;
  font-weight: bold;
}
/************************************************************************************/
/*********home***********************************************************************/
.home-container {
  height: 90vh;
  width: auto;
  background-image: url(/public/assets/clark-street-mercantile-P3pI6xzovu0-unsplash.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.overlay-home {
  background-color: rgba(18, 16, 16, 0.5);
  height: 100%;
  width: 100%;
  padding: 7.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.6rem;
}
.main-heading-home {
  color: white;
  font-size: 8.2rem;
  font-weight: bold;
  text-shadow: 1.2rem 1.4rem 1.4rem rgba(239, 236, 236, 0.3);
}
.heading-home {
  color: white;
  font-size: 4.8rem;
  text-shadow: 1.2rem 1.4rem 1.4rem rgba(239, 236, 236, 0.3);
}
.btn-home {
  padding: 1rem 1.4rem;
  font-size: 1.4rem;
}
/***************************************************************************************/
/*******product************************************************************************/
.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
  padding: 6.4rem;
}

.product-container {
  min-height: 90vh;
  width: 120rem;
  /* margin: 0 auto;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
}
.category-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.heading-products {
  text-align: center;
  font-size: 4.8rem;
  font-weight: bold;
  border-bottom: 1px solid rgb(44, 44, 64);
}
.errormsg {
  color: rgb(225, 117, 117);
}
.skeleton-container {
  min-height: 90vh;
  width: 120rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
}
/********************card product***********************************************************/
.cardProduct-container {
  height: 32rem;
  width: 25rem;
  box-shadow: 1.2rem 1.4rem 1.6rem rgba(0, 0, 0, 0.2);
  border-radius: 0.6rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  border: 1px solid rgb(44, 44, 64);
  transition: 0.3s ease-in;
}
.cardProduct-container:hover {
  transform: scale(1.05);
}
.image-product {
  height: 15rem;
  width: 100%;
  border-bottom: 1px solid rgb(44, 44, 64);
}
.title-product {
  font-size: 1.6rem;
}
.cardProduct-price {
  font-size: 1.4rem;
  font-weight: bold;
}
.btn-cardProduct {
  padding: 0.8rem 1.3rem;
  font-size: 1.3rem;
}
/************details product*************************************************************/
.details-container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
  padding: 6.4rem;
}
.details-text {
  width: 60rem;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}
.details-img {
  height: 40rem;
  width: 40rem;
}
.text-category {
  font-size: 2rem;
  font-weight: bold;
  color: #777;
}
.text-title {
  font-size: 3rem;
}
.text-description {
  font-size: 1.6rem;
}
.text-price {
  font-size: 1.8rem;
  font-weight: bolder;
}
.text-rating {
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.text-rate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.btn-details-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.btn-details {
  font-size: 1.6rem;
  padding: 0.6rem 1.2rem;
}
/*************************************************************************************/
/******cart***************************************************************************/
.cart-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.cardcart-container {
  border: 1px solid #333;
  width: 80rem;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
}
.text-cart {
  text-align: center;
  margin-top: 3.2rem;
}
.img-cart {
  height: 5rem;
  width: 5rem;
}
.title-cart {
  font-size: 1.6rem;
}
.icon-trash {
  font-size: 2rem;
}
.cart-price {
  font-size: 1.4rem;
  font-weight: bold;
}
.cart-quantity {
  font-size: 1.3rem;
  font-weight: bold;
}
.container-qty {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
}
.container-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  gap: 2rem;
  margin-bottom: 3.2rem;
}
.total-cart {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.total-value {
  font-weight: bold;
}
.btn-payement {
  font-size: 1.6rem;
  padding: 1rem 1.4rem;
}
/****************************************************************************************/
/********footer************************************************************************/
.footer-container {
  height: 10rem;
  padding: 2rem;
}
.footer-text {
  font-size: 1.6rem;
  color: white;
  text-align: center;
}
.footer-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  margin-top: 2rem;
}
.icon-footer {
  color: white;
  font-size: 2.4rem;
}
/******************************************************************************/
/*********scroll***************************************************************/
.dropdown-menu::-webkit-scrollbar {
  width: 6px;
}
.dropdown-menu::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0010px rgba(0, 0, 0, 0.1);
}
.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #999;
}
.dropdown-menu::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: #9999999c;
}
/****************loader*****************************************************************/
.loader-container {
  height: 90vh;
  position: relative;
}
.loader-text {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/***************************************************************************************/
/**********responsive*******************************************************************/
@media (max-width: 1030px) {
  html {
    font-size: 58%;
  }
  .product-container {
    width: 90rem;
  }
  .skeleton-container {
    width: 90rem;
  }

  .text-title {
    font-size: 2.8rem;
  }
  .details-text {
    gap: 1rem;
  }
  .container-fluid {
    padding: 0 10rem;
  }
  .btn-group {
    margin-right: 8rem;
  }
  .dropdown-menu {
    width: 25rem;
    height: 25rem;
  }
  .img-cart-dropdown {
    height: 3.5rem;
    width: 3.5rem;
  }
}
@media (max-width: 914px) {
  .product-container {
    width: 80rem;
  }
  .skeleton-container {
    width: 80rem;
  }

  .container-fluid {
    padding: 0 3.2rem;
  }
  .details-container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 6.4rem;
  }
}
@media (max-width: 870px) {
  .product-container {
    width: 75rem;
  }
  .skeleton-container {
    width: 75rem;
  }
  .overlay-home {
    align-items: center;
  }
  .main-heading-home {
    font-size: 7rem;
    text-align: center;
  }
}

@media (max-width: 770px) {
  .product-container {
    width: 70rem;
  }
  .skeleton-container {
    width: 70rem;
  }
  .details-container {
    gap: 2rem;
  }
  .details-text {
    width: 50rem;
  }
  .details-img {
    height: 25rem;
    width: 25rem;
  }
  .text-category {
    font-size: 1.6rem;
    font-weight: bold;
    color: #777;
  }
  .text-title {
    font-size: 2.2rem;
  }
  .text-description {
    font-size: 1.3rem;
  }
  .text-price {
    font-size: 1.6rem;
    font-weight: bolder;
  }
  .text-rating {
    font-size: 1.4rem;
  }

  .btn-details {
    font-size: 1.3rem;
    padding: 0.6rem 1.2rem;
  }
  .cardcart-container {
    width: 70rem;
  }
}

@media (max-width: 550px) {
  .product-container {
    width: 50rem;
  }
  .skeleton-container {
    width: 50rem;
  }
  .main-heading-home {
    font-size: 4.2rem;
  }
  .heading-home {
    font-size: 2.4rem;
  }
  .details-img {
    height: 18rem;
    width: 18rem;
  }
  .cardcart-container {
    width: 50rem;
  }
}
@media (max-width: 415px) {
  html {
    font-size: 53%;
  }
  .main-heading-home {
    font-size: 3rem;
  }
  .product-container {
    width: 39rem;
  }
  .skeleton-container {
    width: 39rem;
  }
  .heading-products {
    font-size: 3.6rem;
  }

  .category-container button {
    font-size: 1.2rem;
    height: 5rem;
    width: 6.5rem;
  }
  .details-text {
    width: 30rem;
  }
  .details-img {
    height: 20rem;
    width: 20rem;
  }
  .text-title {
    font-size: 2rem;
  }
  .cardcart-container {
    width: 38rem;
    gap: 1rem;
    padding: 1.4rem;
  }
  .img-cart {
    height: 5rem;
    width: 5rem;
  }
  .title-cart {
    font-size: 1.3rem;
  }
  .icon-trash {
    font-size: 1.4rem;
  }
  .cart-price {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .cart-quantity {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .container-qty {
    gap: 1rem;
  }
}
@media (max-width: 395px) {
  .main-heading-home {
    font-size: 2.7rem;
  }
  .heading-home {
    font-size: 2.2rem;
  }
  .footer-text {
    font-size: 1.3rem;
  }
  .icon-footer {
    font-size: 2rem;
  }
  .heading-products {
    font-size: 3.2rem;
  }
  .details-img {
    height: 15rem;
    width: 15rem;
  }
  .text-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 380px) {
  .main-heading-home {
    font-size: 2.4rem;
  }
  .heading-home {
    font-size: 1.9rem;
  }
  .product-container {
    width: 35rem;
  }
  .skeleton-container {
    width: 35rem;
  }
  .heading-products {
    font-size: 3rem;
  }
  .text-title {
    font-size: 1.6rem;
  }
  .text-description {
    font-size: 1.1rem;
  }
  .details-container {
    gap: 0rem;
  }
  .text-category {
    font-size: 1.6rem;
  }
  .details-img {
    height: 13.5rem;
    width: 13.5rem;
  }
  .details-text {
    gap: 1rem;
  }
}
@media (max-width: 365px) {
  html {
    font-size: 52%;
  }
  .cardcart-container {
    width: 39rem;
  }
}
@media (max-width: 282px) {
  html {
    font-size: 45%;
  }
}
